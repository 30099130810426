import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Button, Card, Row, Col, Breadcrumb } from "@themesberg/react-bootstrap";
import SortableTable from "../../components/SortableTables";
import { UserContext } from "../../context/UserContext";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jsPDF from "jspdf";
import "jspdf-autotable";

const IncomeExpenseReportPage = () => {
  const [reportData, setReportData] = useState([]);
  const [filters, setFilters] = useState({
    farmSectionId: "",
    userId: "",
    farmId: "",
    type: "both",
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });

  const { getUsers, getFarms, getFarmSections, getIncomeExpenseReport } = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [farms, setFarms] = useState([]);
  const [farmSections, setFarmSections] = useState([]);

  const handleFarmChange = (id) => {
    const fetch = async () => {
      const data = await getFarmSections(id);
      setFarmSections(data);
    };

    fetch();
  };

  useEffect(() => {
    const fetchFilters = async () => {
      const [usersData, farmsData] = await Promise.all([getUsers("admin,manager,employee"), getFarms()]);
      setUsers(usersData);
      setFarms(farmsData);
    };

    fetchFilters();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };
  const formatDate = (dateString) => {
    // Check if the date string includes a 'T' or a space to split date and time parts
    const datePart = dateString.includes('T') ? dateString.split('T')[0] : dateString.split(' ')[0];
  
    // Split the date part to get year, month, and day
    const [year, month, day] = datePart.split('-');
    
    // Format the date parts
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');
    const formattedYear = String(year);
  
    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  };

  const handleSearch = async () => {
    try {
      const data = await getIncomeExpenseReport(filters);
      const dataWithIndex = data.map((innerData, index) => ({
        ...innerData,
        date: formatDate(innerData.date),
        index: index + 1,
      }));
      setReportData(dataWithIndex);
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  };

  const columns = [
    { header: "Date", accessor: "date" },
    { header: "Description", accessor: "Description" },
    { header: "Income", accessor: "income" },
    { header: "Expense", accessor: "expense" },
    { header: "Balance", accessor: "balance" },
  ];

  const generatePDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map(col => col.header);
    const tableRows = reportData.map(data => columns.map(col => data[col.accessor]));

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: {
        cellPadding: 3,
        fontSize: 10,
      },
    });

    doc.save("IncomeExpenseReport.pdf");
  };

  return (
    <div style={{ minHeight: "85vh" }}>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Dashboard</h4>
          <p className="mb-0">Ledger of Tassco Farms</p>
        </div>
      </div>

      <Form style={{ marginBottom: 20 }}>
        <Row>
          <Col md={2}>
            <Form.Group>
              <Form.Label>Report Type</Form.Label>
              <Form.Control as="select" name="type" value={filters.type} onChange={handleFilterChange}>
                <option value="both">Both</option>
                <option value="income">Income</option>
                <option value="expense">Expense</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label>Farm</Form.Label>
              <Form.Control
                as="select"
                name="farmId"
                value={filters.farmId}
                onChange={(e) => {
                  handleFilterChange(e);
                  handleFarmChange(e.target.value);
                }}
              >
                <option value="">All</option>
                {farms.map((farm) => (
                  <option key={farm.farm.id} value={farm.farm.id}>
                    {farm.farm.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label>Farm Section</Form.Label>
              <Form.Control as="select" name="farmSectionId" value={filters.farmSectionId} onChange={handleFilterChange}>
                <option value="">All</option>
                {farmSections.map((section) => (
                  <option key={section.id} value={section.id}>
                    {section.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label>User</Form.Label>
              <Form.Control as="select" name="userId" value={filters.userId} onChange={handleFilterChange}>
                <option value="">All</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group>
              <Form.Label>Date Range</Form.Label>
              <Row>
                <Col md={6}>
                  <Form.Control type="date" name="startDate" value={filters.startDate} onChange={handleFilterChange} />
                </Col>
                <Col md={6}>
                  <Form.Control type="date" name="endDate" value={filters.endDate} onChange={handleFilterChange} />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col md={1} className="d-flex align-items-end">
            <Button variant="primary" onClick={handleSearch}>
              Generate
            </Button>
          </Col>
        </Row>
      </Form>
      {reportData.length > 0 && (
         <Row style={{flexDirection:"column"}}>
          <Col md={2} className="d-flex align-items-end"> <Button variant="secondary" style={{padding:0,marginBottom:4,borderRadius:0}} size="block" onClick={generatePDF}>Download PDF</Button></Col>
        <div>
         
          <SortableTable data={reportData} columns={columns} itemsPerPage={50} noActions={true} />
        </div>
        </Row>
      )}
    </div>
  );
};

export default IncomeExpenseReportPage;
