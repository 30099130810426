import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Button, Card, Alert } from "@themesberg/react-bootstrap";
import Select from "react-select";
import { UserContext } from "../context/UserContext";

const AddSubTask = () => {
  const { taskId, id } = useParams();
  const [detail, setDetail] = useState("");
  const [userId, setUserId] = useState([]);
  const [wageAmount, setWageAmount] = useState("");
  const [wageType, setWageType] = useState("PerAcre");
  const [wageCount, setWageCount] = useState("");
  const [status, setStatus] = useState("Pending");
  const [existingTask, setExistingTask] = useState(0);
  const [taskName, setTaskName] = useState("");
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const history = useHistory();

  const { apiGet, apiPostPut, getUsers } = useContext(UserContext);

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await getUsers("employee");
      setUsers(data.map(user => ({ value: user.id, label: user.name })));
    };

    fetchUsers();

    // Fetch task name for display
    if (taskId) {
      apiGet(`tasks/getTaskByIds?taskId=${taskId}`)
        .then((task) => setTaskName(task.result[0].detail))
        .catch((error) => {
          console.error("Error fetching task:", error);
          setAlert({ show: true, message: "An error occurred while fetching the task data. Please try again.", variant: "danger" });
        });
    }

    if (id) {
      // Fetch the subtask data if editing
      apiGet(`subtasks/getSubTaskByIds?subtaskId=${id}`)
        .then((subtask) => {
          subtask = subtask.result[0];
          setDetail(subtask.detail);
          setTaskName(subtask.task.detail);
          setExistingTask(subtask.task_id);
          setUserId([{ value: subtask.user_id, label: subtask.user.name }]);
          setWageAmount(subtask.wage_amount);
          setWageType(subtask.wage_type);
          setWageCount(subtask.wage_count);
          setStatus(subtask.status);
        })
        .catch((error) => {
          console.error("Error fetching subtask:", error);
          setAlert({ show: true, message: "An error occurred while fetching the subtask data. Please try again.", variant: "danger" });
        });
    }
  }, [id, taskId]);

  const validate = () => {
    let errors = {};
    if (!detail) {
      errors.detail = "Detail is required";
    }
    if (userId.length === 0) {
      errors.userId = "At least one user is required";
    }
    if (!wageAmount) {
      errors.wageAmount = "Wage amount is required";
    }
    if (!wageCount) {
      errors.wageCount = "Wage count is required";
    }
    if (!status) {
      errors.status = "Status is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      const dividedWageAmount = wageType === "PerAcre" ? wageAmount / userId.length : wageAmount;
      const dividedWageCount = wageType === "PerAcre" ? wageCount : wageCount;


      const tasks = userId.map(async (user) => {
        if (id) {
          // Update existing subtask
          const response = await apiPostPut(`subtasks/updateSubtask/${id}`, { detail, task_id: taskId, user_id: user.value, wage_amount: dividedWageAmount, wage_type: wageType, wage_count: dividedWageCount, status }, "PUT");
          return response;
        } else {
          // Add new subtask
          const response = await apiPostPut("subtasks/addSubTask", { detail, task_id: taskId, user_id: user.value, wage_amount: dividedWageAmount, wage_type: wageType, wage_count: dividedWageCount, status });
          return response;
        }
      });
      await Promise.all(tasks);
      setAlert({ show: true, message: `Subtask ${id ? "updated" : "added"} successfully!`, variant: "success" });
      id ? history.push(`/SubTasks/${existingTask}`) : history.push(`/SubTasks/${taskId}`);
    } catch (error) {
      console.error("Error saving subtask:", error);
      setAlert({ show: true, message: "An error occurred while saving the subtask. Please try again.", variant: "danger" });
    }
  };

  return (
    <div style={{ minHeight: "85vh" }}>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">{id ? "Edit Subtask" : "Add Subtask"}</h5>
          {alert.show && (
            <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
              {alert.message}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Task</Form.Label>
              <Form.Control type="text" value={taskName} readOnly />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Detail</Form.Label>
              <Form.Control type="text" placeholder="Enter subtask detail" value={detail} onChange={(e) => setDetail(e.target.value)} isInvalid={!!errors.detail} required />
              <Form.Control.Feedback type="invalid">{errors.detail}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>User</Form.Label>
              <Select
                isMulti
                options={users}
                value={userId}
                onChange={(selected) => setUserId(selected)}
                className={errors.userId && "is-invalid"}
                classNamePrefix="select"
              />
              {errors.userId && <div className="invalid-feedback">{errors.userId}</div>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Wage Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter wage amount" value={wageAmount} onChange={(e) => setWageAmount(e.target.value)} isInvalid={!!errors.wageAmount} required />
              <Form.Control.Feedback type="invalid">{errors.wageAmount}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Wage Type</Form.Label>
              <Form.Control as="select" value={wageType} onChange={(e) => setWageType(e.target.value)} isInvalid={!!errors.wageType} required>
                <option value="PerAcre">Per Acre</option>
                <option value="PerDay">Per Day</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.wageType}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Wage Count</Form.Label>
              <Form.Control type="number" placeholder="Enter wage count" value={wageCount} onChange={(e) => setWageCount(e.target.value)} isInvalid={!!errors.wageCount} required />
              <Form.Control.Feedback type="invalid">{errors.wageCount}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" value={status} onChange={(e) => setStatus(e.target.value)} isInvalid={!!errors.status} required>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
                <option value="Inprogress">In progress</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
              {id ? "Update Subtask" : "Add Subtask"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddSubTask;
