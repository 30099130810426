import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Button } from "@themesberg/react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { FarmSectionTable } from "../components/Tables";
import { UserContext } from "../context/UserContext";

export default () => {
  const { id } = useParams();
  const history = useHistory();
  const { getFarmSections } = useContext(UserContext);
  const [farmSections, setFarmSections] = useState([]);

  const handleAddFarmSectionClick = () => {
    history.push("/AddFarmSection");
  };

  useEffect(() => {
    const fetch = async () => {
      let data = [];
      if (id) {
        data = await getFarmSections(id);
      } else {
        data = await getFarmSections();
      }


      setFarmSections(data);
    };

    fetch();
  }, []);

  return (
    <>
      <div style={{ minHeight: "85vh" }}>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>Farm Sections</Breadcrumb.Item>
              <Breadcrumb.Item active>Farm Sections</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Farm Sections</h4>
            <p className="mb-0">A list of farm sections under Tassco Farms</p>
          </div>
          <div className="align-self-center">
            <Button variant="primary" onClick={handleAddFarmSectionClick}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add Farm Section
            </Button>
          </div>
        </div>

        <FarmSectionTable farmSections={farmSections} />
      </div>
    </>
  );
};

// { id: 1, name: "Section 1", size: "10 acres", farm: "Farm A", startDate: "2022-01-01", endDate: "2022-12-31", status: "Completed" }
