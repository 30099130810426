import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Button, Card, Alert } from "@themesberg/react-bootstrap";
import { UserContext } from "../context/UserContext";

const AddFarm = () => {
  const { apiPostPut, apiGet, getUsers } = useContext(UserContext);
  const { id } = useParams();
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      const data2 = await getUsers("admin");
      setUsers(data2);
    };

    fetch();

    if (id) {
      // Fetch the farm data if editing
      apiGet(`farms/${id}`)
        .then((farm) => {
          setName(farm.result.name);
          setSize(farm.result.size);
        })
        .catch((error) => {
          console.error("Error fetching farm:", error);
          setAlert({ show: true, message: "An error occurred while fetching the farm data. Please try again.", variant: "danger" });
        });

      apiGet(`userFarms/getUserFarmByIds?farmId=${id}`)
        .then((farm) => {
          setUserId(farm?.result[0]?.user_id);
        })
        .catch((error) => {
          console.error("Error fetching farm:", error);
          setAlert({ show: true, message: "An error occurred while fetching the user farm data. Please try again.", variant: "danger" });
        });
    }
  }, [id]);

  const validate = () => {
    let errors = {};
    const nameRegex = /^[a-zA-Z0-9 ]+$/;
    const sizeRegex = /^[0-9]+$/;

    if (!name) {
      errors.name = "Name is required";
    } else if (!nameRegex.test(name)) {
      errors.name = "Name can only contain letters, numbers, and spaces";
    }

    if (!size) {
      errors.size = "Size is required";
    } else if (!sizeRegex.test(size)) {
      errors.size = "Size must be a numeric value";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      if (id) {
        // Update existing farm
        const response = await apiPostPut(`farms/updateFarm/${id}`, { name, size }, "PUT");
        setAlert({ show: true, message: "Farm updated successfully!", variant: "success" });
      } else {
        // Add new farm
        const response = await apiPostPut("farms/addFarms", { name, size });
        if (response.code == 200) {
          const farm_id = response.result.id;
          await apiPostPut("userFarms", { user_id: userId, farm_id, assigned_date: new Date().toISOString().split("T")[0] });
        }
        setAlert({ show: true, message: "Farm added successfully!", variant: "success" });
      }
      history.push("/Farms");
    } catch (error) {
      console.error("Error saving farm:", error);
      setAlert({ show: true, message: "An error occurred while saving the farm. Please try again.", variant: "danger" });
    }
  };

  return (
    <div style={{ minHeight: "85vh" }}>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">{id ? "Edit Farm" : "Add Farm"}</h5>
          {alert.show && (
            <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
              {alert.message}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter farm name" value={name} onChange={(e) => setName(e.target.value)} isInvalid={!!errors.name} required />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Size</Form.Label>
              <Form.Control type="text" placeholder="Enter farm size in acres" value={size} onChange={(e) => setSize(e.target.value)} isInvalid={!!errors.size} required />
              <Form.Control.Feedback type="invalid">{errors.size}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>User</Form.Label>
              <Form.Control as="select" value={userId} onChange={(e) => setUserId(e.target.value)} isInvalid={!!errors.userId} required>
                <option value="" disabled>Select a user</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.userId}</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
              {id ? "Update Farm" : "Add Farm"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddFarm;
