import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Button, Card, Alert } from "@themesberg/react-bootstrap";
import { UserContext } from "../context/UserContext";

const AddTask = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [userId, setUserId] = useState("");
  const [farmId, setFarmId] = useState("");
  const [farmSectionId, setFarmSectionId] = useState("");
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [completionDate, setCompletionDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState("Pending");
  const [users, setUsers] = useState([]);
  const [farms, setFarms] = useState([]);
  const [farmSections, setFarmSections] = useState([]);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const history = useHistory();
  const { apiGet, apiPostPut, getFarms, getFarmSections, getUsers } = useContext(UserContext);

  const handleFormChange = (id) => {
    setFarmId(id);
    const fetch = async () => {
      const data = await getFarmSections(id);
      console.log(data);
      setFarmSections(data);
    };

    fetch();
  };

  useEffect(() => {
    // Fetch users, farms, and farm sections for dropdowns

    const fetch = async () => {
      const data = await getFarms();
      setFarms(data);

      const data2 = await getUsers("manager,admin");
      setUsers(data2);
    };

    fetch();

    if (id) {
      // Fetch the task data if editing
      apiGet(`tasks/getTaskByIds?taskId=${id}`)
        .then((task) => {
          task = task?.result[0];
          setDetail(task.detail);
          setFeedback(task.feedback);
          setUserId(task.user_id);
          setFarmId(task.farm_id);
          handleFormChange(task.farm_id);
          setFarmSectionId(task.farm_section_id);
          setStartDate(task.start_date.slice(0, 10));
          setCompletionDate(task.complition_date.slice(0, 10));
          setStatus(task.status);
        })
        .catch((error) => {
          console.error("Error fetching task:", error);
          setAlert({ show: true, message: "An error occurred while fetching the task data. Please try again.", variant: "danger" });
        });
    }
  }, [id]);

  const validate = () => {
    let errors = {};
    if (!detail) {
      errors.detail = "Detail is required";
    }
    if (!userId) {
      errors.userId = "User is required";
    }
    if (!farmId) {
      errors.farmId = "Farm is required";
    }
    if (!farmSectionId) {
      errors.farmSectionId = "Farm section is required";
    }
    if (!startDate) {
      errors.startDate = "Start date is required";
    }
    if (!completionDate) {
      errors.completionDate = "Completion date is required";
    }
    if (!status) {
      errors.status = "Status is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      if (id) {
        // Update existing task
        const response = await apiPostPut(`tasks/updateTask/${id}`, { detail, feedback, user_id: userId, farm_id: farmId, farm_section_id: farmSectionId, start_date: startDate, complition_date: completionDate, status }, "PUT");
        setAlert({ show: true, message: "Task updated successfully!", variant: "success" });
      } else {
        // Add new task
        const response = await apiPostPut("tasks/addTask", { detail, feedback, user_id: userId, farm_id: farmId, farm_section_id: farmSectionId, start_date: startDate, complition_date: completionDate, status });
        setAlert({ show: true, message: "Task added successfully!", variant: "success" });
      }
      history.push("/Tasks/"+farmId);
    } catch (error) {
      console.error("Error saving task:", error);
      setAlert({ show: true, message: "An error occurred while saving the task. Please try again.", variant: "danger" });
    }
  };

  return (
    <div style={{ minHeight: "85vh" }}>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">{id ? "Edit Task" : "Add Task"}</h5>
          {alert.show && (
            <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
              {alert.message}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Detail</Form.Label>
              <Form.Control type="text" placeholder="Enter task detail" value={detail} onChange={(e) => setDetail(e.target.value)} isInvalid={!!errors.detail} required />
              <Form.Control.Feedback type="invalid">{errors.detail}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Feedback</Form.Label>
              <Form.Control type="text" placeholder="Enter feedback" value={feedback} onChange={(e) => setFeedback(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>User</Form.Label>
              <Form.Control as="select" value={userId} onChange={(e) => setUserId(e.target.value)} isInvalid={!!errors.userId} required>
                <option value="">Select a user</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.userId}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Farm</Form.Label>
              <Form.Control as="select" value={farmId} onChange={(e) => handleFormChange(e.target.value)} isInvalid={!!errors.farmId} required>
                <option value="" disabled>
                  Select a farm
                </option>
                {farms.map((farm) => (
                  <option key={farm.farm.id} value={farm.farm.id}>
                    {farm.farm.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.farmId}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Farm Section</Form.Label>
              <Form.Control as="select" value={farmSectionId} onChange={(e) => setFarmSectionId(e.target.value)} isInvalid={!!errors.farmSectionId} required>
                <option value="" disabled>
                  Select a farm section
                </option>
                {farmSections.map((section) => (
                  <option key={section.id} value={section.id}>
                    {section.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.farmSectionId}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Start Date and Completion Date</Form.Label>
              <div className="d-flex">
                <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} isInvalid={!!errors.startDate} required className="me-3" />
                <Form.Control type="date" value={completionDate} onChange={(e) => setCompletionDate(e.target.value)} isInvalid={!!errors.completionDate} required />
              </div>
              <div className="d-flex">
                <Form.Control.Feedback type="invalid" className="me-3">
                  {errors.startDate}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.completionDate}</Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" value={status} onChange={(e) => setStatus(e.target.value)} isInvalid={!!errors.status} required>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
                <option value="Inprogress">In progress</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
              {id ? "Update Task" : "Add Task"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddTask;
