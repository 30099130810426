import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Breadcrumb, Alert } from "@themesberg/react-bootstrap";
import Select from "react-select";
import SortableTable from "../components/SortableTables";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jsPDF from "jspdf";
import "jspdf-autotable";

const WagesReport = () => {
    const [reportData, setReportData] = useState([]);
    const [filters, setFilters] = useState({
        startDate: new Date().toISOString().split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
        farmIds: [],
        farmSectionId: "",
        userId: ""
    });

    const { getFarms, getUsers, getWagesReport, getFarmSections, apiPostPut } = useContext(UserContext);
    const { authInfo } = useContext(AuthContext);

    const [alert, setAlert] = useState({ show: false, message: "", variant: "" });

    const [farms, setFarms] = useState([]);
    const [farmSections, setFarmSections] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedFarmNames, setSelectedFarmNames] = useState([]);

    useEffect(() => {
        const fetchFilters = async () => {
            const farmsData = await getFarms();
            const usersData = await getUsers("employee"); // Fetch employees
            setFarms(farmsData.map(farm => ({ value: farm.farm.id, label: farm.farm.name })));
            setUsers(usersData.map(user => ({ value: user.id, label: user.name })));
        };

        fetchFilters();
    }, []);

    const handleFarmChange = async (selectedOptions) => {
       
        const selectedFarmIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFilters({ ...filters, farmIds: selectedFarmIds, farmSectionId: ""  });

        if (selectedFarmIds.length === 1) {
            const selectedFarm = farms.find(farm => farm.value === selectedFarmIds[0]);
            setSelectedFarmNames([selectedFarm.label]);

            // Fetch farm sections based on selected farm
            const sections = await getFarmSections(selectedFarmIds[0]);
            setFarmSections(sections);
        } else {
            setSelectedFarmNames(selectedOptions ? selectedOptions.map(option => option.label) : []);
            setFarmSections([]);
        }
    };

    const handleUserChange = (selectedOption) => {
        setFilters({ ...filters, userId: selectedOption ? selectedOption.value : "" });
    };

    const handleSearch = async () => {
        try {
            const searchFilters = { ...filters };
            
            // Remove farmSectionId if multiple farms are selected
            if (filters.farmIds.length > 1) {
                delete searchFilters.farmSectionId;
            }
            
            const data = await getWagesReport(searchFilters);
            const dataWithIndex = data.map((innerData, index) => ({
                ...innerData,
                index: index + 1,
            }));
            setReportData(dataWithIndex);
        } catch (error) {
            console.error("Error fetching report data:", error);
        }
    };
    

    const generatePDF = () => {
        const doc = new jsPDF();
        const tableColumn = ["User Name", "Task Details", "Total Wage"];
        const tableRows = reportData.map(data => [
            data.userName,
            data.subTasksDetails.replace(/<[^>]*>/g, ''), // Remove HTML tags for PDF
            data.totalWage
        ]);

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 20,
            styles: {
                cellPadding: 3,
                fontSize: 10,
            },
        });

        doc.save("WagesReport.pdf");
    };

    const handleCreateExpense = async () => {
        try {
            // Aggregate data by farmId and farmSectionId
            const expensesBySection = reportData.reduce((acc, user) => {
                const key = `${user.farmId}-${user.farmSectionId}`; // Create a unique key for each farmId and farmSectionId combination
    
                if (!acc[key]) {
                    acc[key] = {
                        farmName: user.subTasksDetails.match(/<li>(.*?) -/)[1], // Extract farm name from the subTasksDetails
                        farmSectionName: user.subTasksDetails.match(/ - (.*?) - Total Wage:/)[1], // Extract farm section name
                        farmId: user.farmId,
                        farmSectionId: user.farmSectionId,
                        totalWage: 0,
                        userId: authInfo.data.id,
                        date: new Date().toISOString().split("T")[0],
                    };
                }
    
                acc[key].totalWage += user.totalWage;
                return acc;
            }, {});
    
            // Create expense for each farm and section combination
            for (const key in expensesBySection) {
                const expenseData = expensesBySection[key];
                const response = await apiPostPut("accounts/addExpenses", {
                    name: `Salaries ${expenseData.farmName} - ${expenseData.farmSectionName} - ${filters.startDate} to ${filters.endDate}`,
                    description: `Salaries for ${expenseData.farmName}, Section: ${expenseData.farmSectionName} - ${filters.startDate} to ${filters.endDate}`,
                    expensesType: "payroll",
                    user_id: expenseData.userId,
                    farm_id: expenseData.farmId, // Send farmId
                    farm_section_id: expenseData.farmSectionId, // Send farmSectionId
                    date: expenseData.date,
                    Amount: expenseData.totalWage,
                });
    
                if (response.code !== 200) {
                    setAlert({ show: true, message: "Something went wrong with creating expenses.", variant: "danger" });
                    return;
                }
            }
    
            setAlert({ show: true, message: "Expenses added successfully!", variant: "success" });
        } catch (error) {
            console.error("Error creating expense:", error);
            setAlert({ show: true, message: "Error creating expense.", variant: "danger" });
        }
    };
    
    
    
    

    const columns = [
        { header: "User Name", accessor: "userName" },
        { header: "Tasks Details", accessor: "subTasksDetails" },
        { header: "Total Wage", accessor: "totalWage" },
    ];

    const totalWages = reportData.reduce((total, user) => total + user.totalWage, 0);

    return (
        <div style={{ minHeight: "85vh" }}>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Wages Report</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Wages Report</h4>
                    <p className="mb-0">Calculate Wage of Tassco Farms Employees</p>
                </div>
            </div>

            <Form style={{ marginBottom: 20 }}>
                <Row>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Date Range</Form.Label>
                            <Row>
                                <Col md={6}>
                                    <Form.Control type="date" name="startDate" value={filters.startDate} onChange={e => setFilters({ ...filters, startDate: e.target.value })} />
                                </Col>
                                <Col md={6}>
                                    <Form.Control type="date" name="endDate" value={filters.endDate} onChange={e => setFilters({ ...filters, endDate: e.target.value })} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Farm</Form.Label>
                            <Select
                                options={farms}
                                value={farms.filter(farm => filters.farmIds.includes(farm.value))}
                                onChange={handleFarmChange}
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </Form.Group>
                    </Col>

                    <Col md={2}>
                        <Form.Group>
                            <Form.Label>Farm Section</Form.Label>
                            <Form.Control
                                as="select"
                                name="farmSectionId"
                                value={filters.farmSectionId}
                                onChange={e => setFilters({ ...filters, farmSectionId: e.target.value })}
                                disabled={filters.farmIds.length !== 1}  // Disable if more than one farm is selected
                            >
                                <option value="">All Sections</option>
                                {farmSections.map(section => (
                                    <option key={section.id} value={section.id}>
                                        {section.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col md={2}>
                        <Form.Group>
                            <Form.Label>User</Form.Label>
                            <Select
                                options={users}
                                value={users.find(user => user.value === filters.userId)}
                                onChange={handleUserChange}
                                isClearable
                                className="basic-single"
                                classNamePrefix="select"
                            />
                        </Form.Group>
                    </Col>

                    <Col md={1} className="d-flex align-items-end">
                        <Button variant="primary" onClick={handleSearch}>
                            Generate
                        </Button>
                    </Col>
                </Row>
            </Form>

            {alert.show && (
                <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
                    {alert.message}
                </Alert>
            )}

            {reportData.length > 0 && (
                <div>
                    <Button variant="secondary" style={{ marginBottom: 10 }} onClick={generatePDF}>
                        Download PDF
                    </Button>
                    <SortableTable
                        data={reportData}
                        columns={columns}
                        itemsPerPage={50}
                        noActions={true}
                        isHtmlContent={true}
                        footer={
                            <tfoot>
                                <tr style={{ background: "#000", color: "#fff" }}>
                                    <td></td>
                                    <td><b>Total Wages</b></td>
                                    <td></td>
                                    <td>{totalWages} -  <Button variant="link" style={{ background: "#fff" }} size="sm" onClick={handleCreateExpense}>
                                        Create Expense
                                    </Button></td>

                                </tr>
                            </tfoot>
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default WagesReport;
