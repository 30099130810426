import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Button } from "@themesberg/react-bootstrap";
import { IncomeTable } from "../components/Tables";
import { useHistory, useParams } from "react-router-dom";
import { UserContext } from "../context/UserContext"; // Import UserContext

export default () => {
  const { id } = useParams(); // To get the ID from the URL
  const { getIncome } = useContext(UserContext); // Fetch getIncomes from context
  const history = useHistory();
  const [incomes, setIncomes] = useState([]);

  const handleAddIncomeClick = () => {
    history.push("/AddIncome/" + id);
  };

  useEffect(() => {
    const fetch = async () => {
      let data = [];
      if (id) {
        data = await getIncome(id);
      } else {
        data = await getIncome();
      }
      setIncomes(data);
    };

    fetch();
  }, [id]);

  return (
    <>
      <div style={{ minHeight: "85vh" }}>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>Income</Breadcrumb.Item>
              <Breadcrumb.Item>Transactions</Breadcrumb.Item>
              <Breadcrumb.Item active>Income</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Income</h4>
            <p className="mb-0">A list of income transactions under Tassco Farms</p>
          </div>
          <div className="align-self-center">
            <Button variant="primary" onClick={handleAddIncomeClick}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add Income
            </Button>
          </div>
        </div>

        <IncomeTable incomes={incomes} />
      </div>
    </>
  );
};
