import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Button, Card, Alert } from "@themesberg/react-bootstrap";

import { UserContext } from "../context/UserContext";

const AddUser = () => {
  const { apiPostPut, apiGet } = useContext(UserContext);
  const { id } = useParams();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("employee");
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const history = useHistory();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (id) {
      // Fetch the user data if editing
      apiGet(`auth/getUserById/${id}`)
        .then((user) => {
          user = user.result;
          setName(user.name);
          setUsername(user.username);
          setRole(user.role);
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
          setAlert({ show: true, message: "An error occurred while fetching the user data. Please try again.", variant: "danger" });
        });
    }
  }, [id]);

  const validate = () => {
    let errors = {};
    const nameRegex = /^[a-zA-Z0-9 ]+$/;
    const usernameRegex = /^[a-zA-Z0-9_]+$/;

    if (!name) {
      errors.name = "Name is required";
    } else if (!nameRegex.test(name)) {
      errors.name = "Name can only contain letters, numbers, and spaces";
    }

    // if (!username) {
    //   errors.username = "Username is required";
    // } else if (!usernameRegex.test(username)) {
    //   errors.username = "Username can only contain letters, numbers, and underscores";
    // }

    if (!id) {
      // if (!password) {
      //   errors.password = "Password is required";
      // } else if (password.length < 6) {
      //   errors.password = "Password must be at least 6 characters long";
      // }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      if (id) {
        // Update existing user
        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        formData.append('username', username);
        formData.append('role', role);
  
        if (image) {
          formData.append('image', image);
        }

        const response = await apiPostPut(`auth/updateUser`, formData);
        setAlert({ show: true, message: "User updated successfully!", variant: "success" });
      } else {
        // Add new user
        const response = await apiPostPut("auth/register", { name, username, password, role });
        setAlert({ show: true, message: "User added successfully!", variant: "success" });
      }
      history.push("/Users");
    } catch (error) {
      console.error("Error saving user:", error);
      setAlert({ show: true, message: "An error occurred while saving the user. Please try again.", variant: "danger" });
    }
  };

  return (
    <div style={{ minHeight: "85vh" }}>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">{id ? "Edit User" : "Add User"}</h5>
          {alert.show && (
            <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
              {alert.message}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter full name" value={name} onChange={(e) => setName(e.target.value)} isInvalid={!!errors.name} required />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Enter username" value={username} onChange={(e) => setUsername(e.target.value)} isInvalid={!!errors.username}  />
              <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            </Form.Group>
            {!id && (
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} isInvalid={!!errors.password}  />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)} required>
                <option value="admin">Admin</option>
                <option value="manager">Manager</option>
                <option value="employee">Employee</option>
              </Form.Control>
            </Form.Group>

            {id && (
              <Form.Group className="mb-3">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control type="file" onChange={(e) => setImage(e.target.files[0])} />
              </Form.Group>
            )}

            <Button variant="primary" type="submit">
              {id ? "Update User" : "Add User"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddUser;
