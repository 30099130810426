import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Button, Card, Alert } from "@themesberg/react-bootstrap";
import { apiGet, apiPostPut } from "../data/API";
import { UserContext } from "../context/UserContext";

const AddFarmSection = () => {
  const { apiGet, apiPostPut, getFarms } = useContext(UserContext);
  const { id } = useParams();
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [farmId, setFarmId] = useState("");
  const [farms, setFarms] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const history = useHistory();




  useEffect(() => {
    // Fetch farms for dropdown
    const fetch = async () => {
      const data = await getFarms();
      setFarms(data);
    };

    fetch();

    if (id) {
      // Fetch the farm section data if editing
      apiGet("farmSections/getFarmSectionsByIds?farmSectionId=" + id)
        .then((section) => {
          section.result = section.result[0]
          setName(section.result.name);
          setSize(section.result.size);
          setFarmId(section.result.farm_id);
          setStartDate(section.result.start_date.slice(0, 10));
          setEndDate(section.result.end_date.slice(0, 10));
        })
        .catch((error) => {
          console.error("Error fetching farm section:", error);
          setAlert({ show: true, message: "An error occurred while fetching the farm section data. Please try again.", variant: "danger" });
        });
    }
  }, [id]);

  const validate = () => {
    let errors = {};
    if (!name) {
      errors.name = "Name is required";
    }
    if (!size) {
      errors.size = "Size is required";
    }
    if (!farmId) {
      errors.farmId = "Farm is required";
    }
    if (!startDate) {
      errors.startDate = "Start date is required";
    }
    if (!endDate) {
      errors.endDate = "End date is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      if (id) {
        // Update existing farm section
        const response = await apiPostPut(`farmSections/updateFarmSections/${id}`, { name, size, farm_id: farmId, start_date: startDate, end_date: endDate }, "PUT");
        setAlert({ show: true, message: "Farm section updated successfully!", variant: "success" });
      } else {
        // Add new farm section
        const response = await apiPostPut("farmSections", { name, size, farm_id: farmId, start_date: startDate, end_date: endDate });
        setAlert({ show: true, message: "Farm section added successfully!", variant: "success" });
      }
      history.push("/FarmSections/"+farmId);
    } catch (error) {
      console.error("Error saving farm section:", error);
      setAlert({ show: true, message: "An error occurred while saving the farm section. Please try again.", variant: "danger" });
    }
  };

  return (
    <div style={{ minHeight: "85vh" }}>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">{id ? "Edit Farm Section" : "Add Farm Section"}</h5>
          {alert.show && (
            <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
              {alert.message}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} isInvalid={!!errors.name} required />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Size</Form.Label>
              <Form.Control type="text" placeholder="Enter size" value={size} onChange={(e) => setSize(e.target.value)} isInvalid={!!errors.size} required />
              <Form.Control.Feedback type="invalid">{errors.size}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Farm</Form.Label>
              <Form.Control as="select" value={farmId} onChange={(e) => setFarmId(e.target.value)} isInvalid={!!errors.farmId} required>
                <option value="" >Select a farm</option>
                {farms.map((farm) => (
                  <option key={farm.farm.id} value={farm.farm.id}>
                    {farm.farm.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.farmId}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Start Date and End Date</Form.Label>
              <div className="d-flex">
                <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} isInvalid={!!errors.startDate} required className="me-3" />
                <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} isInvalid={!!errors.endDate} required />
              </div>
              <div className="d-flex">
                <Form.Control.Feedback type="invalid" className="me-3">
                  {errors.startDate}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.endDate}</Form.Control.Feedback>
              </div>
            </Form.Group>
            <Button variant="primary" type="submit">
              {id ? "Update Farm Section" : "Add Farm Section"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddFarmSection;
