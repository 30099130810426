import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Button } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { FarmsTable } from "../components/Tables";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";

export default () => {
  const { getFarms } = useContext(UserContext);
  const { authInfo } = useContext(AuthContext);

  const [farms, setFarms] = useState([]);
  const history = useHistory();

  const handleAddFarmClick = () => {
    history.push("/AddFarm");
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getFarms();
      setFarms(data);
    };

    fetch();
  }, []);

  return (
    <>
      <div style={{ minHeight: "85vh" }}>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>Farms</Breadcrumb.Item>
              <Breadcrumb.Item active>Farms</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Farms</h4>
            <p className="mb-0">A list of farms under Tassco Farms</p>
          </div>
          {authInfo.data.role == "supperadmin" && (
            <div className="align-self-center">
              <Button variant="primary" onClick={handleAddFarmClick}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add Farm
              </Button>
            </div>
          )}
        </div>

        <FarmsTable farms={farms} />
      </div>
    </>
  );
};

//{name:'Amir',size:200}
