import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Button } from "@themesberg/react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { SubtasksTable } from "../components/Tables";
import { UserContext } from "../context/UserContext";

export default () => {
  const { id } = useParams();
  const history = useHistory();
  const { getSubTasks } = useContext(UserContext);
  const [subtasks, setSubtasks] = useState([]);

  const handleAddTaskClick = () => {
    history.push("/AddSubTask/"+id);
  };

  useEffect(() => {
    const fetch = async () => {
      let data = [];
      if (id) {
        data = await getSubTasks(id);
        console.log(data)
      } else {
        data = await getSubTasks();
      }
      setSubtasks(data);
    };

    fetch();
  }, [id]);

  return (
    <>
      <div style={{ minHeight: "85vh" }}>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>Tasks</Breadcrumb.Item>
              <Breadcrumb.Item active>Sub Tasks</Breadcrumb.Item>
            </Breadcrumb>
            <h4>{subtasks[0]?.task?.detail} - Sub Tasks</h4>
            <p className="mb-0">A list of sub tasks under {subtasks[0]?.task?.detail}</p>
          </div>
          <div className="align-self-center">
            <Button variant="primary" onClick={handleAddTaskClick}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add Subtask
            </Button>
          </div>
        </div>

        <SubtasksTable subtasks={subtasks} />
      </div>
    </>
  );
};
