import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import axios from "axios";
import { BASE_URL } from "../config";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { authInfo } = useContext(AuthContext);
  const [userDetail, setuserDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  const apiGet = async (endpoint, token = authInfo?.access_token) => {
    try {
      const headers = token ? { Authorization: `${token}` } : {};
      const response = await axios.get(`${BASE_URL}${endpoint}`, { headers });
      return response.data;
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
      throw error;
    }
  };

  const apiPostPut = async (endpoint, data, method = "POST", token = authInfo?.access_token) => {
    try {
      const headers = token ? { Authorization: `${token}` } : {};
      const response = await axios({
        method,
        url: `${BASE_URL}${endpoint}`,
        data,
        headers,
      });
      return response.data;
    } catch (error) {
      console.error(`Error ${method === "POST" ? "posting" : "putting"} data to ${endpoint}:`, error);
      throw error;
    }
  };

  const apiDelete = async (endpoint, token = authInfo?.access_token) => {
    try {
      const headers = token ? { Authorization: `${token}` } : {};
      const response = await axios.delete(`${BASE_URL}${endpoint}`, { headers });
      return response.data;
    } catch (error) {
      console.error(`Error deleting data from ${endpoint}:`, error);
      throw error;
    }
  };

  //Users ...................................................

  //FARM SECTIONS ...................................................
  const getFarmSections = async (id) => {
    try {
      const url = id ? "farmSections/getFarmSectionsByIds?farmId=" + id : "farmSections/getFarmSectionsByIds";
      const data = await apiGet(url, authInfo?.access_token);
      if (data.code == 200) {
        return data.result;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  //FARMS ...................................................
  const getFarms = async () => {
    try {
      const url = authInfo.data.role == "supperadmin" ? "userFarms/getUserFarmByIds" : "userFarms/getUserFarmByIds?userId=" + authInfo.data.id;
      const data = await apiGet(url, authInfo?.access_token);

      if (data.code == 200) {
        return data.result;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  //Tasks ...................................................
  const getTasks = async (id) => {
    try {
      const url = id ? "tasks/getTaskByIds?farmId=" + id : "tasks/getTaskByIds?userId=" + authInfo.data.id;

      const data = await apiGet(url, authInfo?.access_token);
      console.log(data)
      if (data.code == 200) {
        return data.result;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  //Income ...................................................
  const getIncome = async (id) => {
    try {
      const url = id ? "accounts/getIncomeByIds?farmId=" + id : "accounts/getIncomeByIds?userId=" + authInfo.data.id;

      const data = await apiGet(url, authInfo?.access_token);
      if (data.code == 200) {
        return data.result;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  //Expense ...................................................
  const getExpense = async (id) => {
    try {
      const url = id ? "accounts/getExpesesByIds?farmId=" + id : "accounts/getExpesesByIds";

      const data = await apiGet(url, authInfo?.access_token);
      if (data.code == 200) {
        return data.result;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  //Tasks ...................................................
  const getUsers = async (role) => {
    try {
      const url = role ? "auth/getAllUsers?role=" + role : "auth/getAllUsers?";
      const data = await apiGet(url, authInfo?.access_token);
      if (data.code == 200) {
        return data.result;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  //SubTasks ...................................................
  const getSubTasks = async (id) => {
    try {
      const url = id ? "subtasks/getSubTaskByIds?taskId=" + id : "subtasks/getSubTaskByIds";
      const data = await apiGet(url, authInfo?.access_token);
      if (data.code == 200) {
        return data.result;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  // Fetch the Income and Expense Report data
  const getIncomeExpenseReport = async (filters) => {
    try {
      const params = new URLSearchParams(filters).toString();
      const url = `accounts/getIncExpReport?${params}`;
      const data = await apiGet(url, authInfo?.access_token);
      if (data.code == 200) {
        return data.result;
      }
      return [];
    } catch (error) {
      console.error("Error fetching report data:", error);
      return [];
    }
  };

  // Fetch the Income and Expense Report data
  const getWagesReport = async (filters) => {
    try {
      const params = new URLSearchParams(filters).toString();
      const url = `accounts/wagesReport?${params}`;
      const data = await apiGet(url, authInfo?.access_token);
      if (data.code == 200) {
        return data.result;
      }
      return [];
    } catch (error) {
      console.error("Error fetching report data:", error);
      return [];
    }
  };

  return (
    <UserContext.Provider
      value={{
        errorMessage,
        userDetail,
        isLoading,
        getFarms,
        getFarmSections,
        getTasks,
        getSubTasks,
        getUsers,
        getIncome,
        getExpense,
        getIncomeExpenseReport,
        getWagesReport,
        apiPostPut,
        apiDelete,
        apiGet,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
