export const Routes = {
  // pages
  Signin: { path: "/" },
  DashboardOverview: { path: "/Dashboard" },
  WagesReport: { path: "/Wages" },

  Users: { path: "/Users" },
  AddUser: { path: "/AddUser" },
  UpdateUser: {path: "/UpdateUser/:id?"},
  Farms: { path: "/Farms" },
  AddFarm: { path: "/AddFarm" },
  UpdateFarm: { path: "/UpdateFarm/:id?" },
  Tasks: { path: "/Tasks/:id?" },
  AllTasks: { path: "/Tasks" },

  AddTask: { path: "/AddTask" },
  UpdateTask: { path: "/UpdateTask/:id?" },

  SubTasks: { path: "/SubTasks/:id?" },
  AddSubTask: { path: "/AddSubTask/:taskId?" },
  UpdateSubtask: { path: "/UpdateSubtask/:id?" },

  Income: { path: "/Income/:id?" },
  AllIncome: { path: "/Income" },
  AddIncome: { path: "/AddIncome/:id?" },


  Expense: { path: "/Expense/:id?" },
  AllExpense: { path: "/Expense" },

  AddExpense: { path: "/AddExpense/:id?" },

  Transactions: { path: "/Transactions" },
  FarmSections: { path: "/FarmSections/:id?" },
  AllFarmSections: { path: "/FarmSections" },

  AddFarmSection: { path: "/AddFarmSection" },
  UpdateFarmSection: { path: "/UpdateFarmSection/:id?" },

  Presentation: { path: "/presentation" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  // Signin: { path: "/examples/sign-in" },
  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
