import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Button } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { UsersTable } from "../components/Tables";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";

export default () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const { getUsers } = useContext(UserContext);
  const { authInfo } = useContext(AuthContext);

  const handleAddUserClick = () => {
    history.push("/AddUser");
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getUsers();
      console.log(data);
      setUsers(data);
    };

    fetch();
  }, []);

  return (
    <>
      <div style={{ minHeight: "85vh" }}>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>Users</Breadcrumb.Item>
              <Breadcrumb.Item active>Users</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Users</h4>
            <p className="mb-0">A list of users under Tassco Farms</p>
          </div>
          <div className="align-self-center">
            <Button variant="primary" onClick={handleAddUserClick}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add User
            </Button>
          </div>
        </div>

        <UsersTable users={users} />
      </div>
    </>
  );
};
