import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faAngleDown, faAngleUp, faArrowDown, faTasks, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt, faTrash, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup, FormControl, Modal, Tooltip, OverlayTrigger } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import transactions from "../data/transactions";
import commands from "../data/commands";
import SortableTable from "./SortableTables";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return value ? (
    <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

export const PageVisitsTable = () => {
  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>${returnValue}</td>
        <td>
          <FontAwesomeIcon icon={bounceIcon} className={`${bounceTxtColor} me-3`} />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className="text-end">
            <Button variant="secondary" size="sm">
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Page name</th>
            <th scope="col">Page Views</th>
            <th scope="col">Page Value</th>
            <th scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map((pv) => (
            <TableRow key={`page-visit-${pv.id}`} {...pv} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

const formatDate = (dateString) => {
  // Check if the date string includes a 'T' or a space to split date and time parts
  const datePart = dateString.includes('T') ? dateString.split('T')[0] : dateString.split(' ')[0];

  // Split the date part to get year, month, and day
  const [year, month, day] = datePart.split('-');
  
  // Format the date parts
  const formattedDay = String(day).padStart(2, '0');
  const formattedMonth = String(month).padStart(2, '0');
  const formattedYear = String(year);

  return `${formattedDay}/${formattedMonth}/${formattedYear}`;
};



export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const { id, source, sourceIcon, sourceIconColor, sourceType, category, rank, trafficShare, change } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">
            {id}
          </Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon icon={sourceIcon} className={`icon icon-xs text-${sourceIconColor} w-30`} />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar variant="primary" className="progress-lg mb-0" now={trafficShare} min={0} max={100} />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map((pt) => (
              <TableRow key={`page-traffic-${pt.id}`} {...pt} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const FarmsTable = ({ farms }) => {
  const { apiDelete } = useContext(UserContext);
  const { authInfo } = useContext(AuthContext);

  const history = useHistory();
  const [showModal, setShowModal] = React.useState(false);
  const [selectedFarm, setSelectedFarm] = React.useState(null);

  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Size", accessor: "size" },
    { header: "Assigned To", accessor: "user" },
  ];

  const handleDelete = async () => {
    if (selectedFarm) {
      try {
        const response = await apiDelete(`farms/deleteFarm/${selectedFarm.farm_id}`);
        if (response.code == 200) window.location.reload();
        else alert("Something Went Wrong.");
      } catch (error) {
        // Handle error, e.g., show an error message
        console.error("There was an error deleting the farm!", error);
      } finally {
        setShowModal(false);
        setSelectedFarm(null);
      }
    }
  };

  const renderActions = (farm) => (
    <>
      {authInfo.data.role == "supperadmin" && (
        <>
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>}>
            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => history.push(`/UpdateFarm/${farm.farm_id}`)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}>
            <Button
              variant="outline-danger"
              size="sm"
              className="me-2"
              onClick={() => {
                setSelectedFarm(farm);
                setShowModal(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </OverlayTrigger>
        </>
      )}
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Farm Sections</Tooltip>}>
        <Button variant="outline-info" size="sm" className="me-2" onClick={() => history.push(`/FarmSections/${farm.farm_id}`)}>
          <FontAwesomeIcon icon={faCircleNotch} />
        </Button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Tasks</Tooltip>}>
        <Button variant="outline-primary" size="sm" className="me-2" onClick={() => history.push(`/Tasks/${farm.farm_id}`)}>
          <FontAwesomeIcon icon={faTasks} />
        </Button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Income</Tooltip>}>
        <Button variant="outline-success" size="sm" className="me-2" onClick={() => history.push(`/Income/${farm.farm_id}`)}>
          <FontAwesomeIcon icon={faArrowDown} />
        </Button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Expense</Tooltip>}>
        <Button variant="outline-danger" size="sm" className="me-2" onClick={() => history.push(`/Expense/${farm.farm_id}`)}>
          <FontAwesomeIcon icon={faArrowUp} />
        </Button>
      </OverlayTrigger>
    </>
  );

  const dataWithIndex = farms.map((farm, index) => ({
    ...farm,
    name: farm.farm.name,
    size: farm.farm.size,
    user: farm.user.name,
    index: index + 1,
  }));

  return (
    <>
      <SortableTable data={dataWithIndex} columns={columns} renderActions={renderActions} />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this farm?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const UsersTable = ({ users }) => {
  const { apiDelete } = useContext(UserContext);
  const { authInfo } = useContext(AuthContext);

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Username", accessor: "username" },
    { header: "Role", accessor: "role" },
    { header: "Verified", accessor: "verified" },
  ];

  const handleDelete = async () => {
    if (selectedUser) {
      try {
        const response = await apiDelete(`auth/deleteUser/${selectedUser.id}`);
        if (response.code === 200) window.location.reload();
        else alert("Something went wrong.");
      } catch (error) {
        console.error("There was an error deleting the user!", error);
      } finally {
        setShowModal(false);
        setSelectedUser(null);
      }
    }
  };

  const renderActions = (user) => (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>}>
        <Button variant="outline-primary" size="sm" className="me-2" onClick={() => history.push(`/UpdateUser/${user.id}`)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => {
            setSelectedUser(user);
            setShowModal(true);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </OverlayTrigger>
    </>
  );

  const dataWithVerifiedBadge = users
    .filter((data) => {
      if (authInfo.data.role !== "supperadmin") {
        return data.role === "manager" || data.role === "employee";
      } else {
        return data.role !== "supperadmin";
      }
    })
    .map((user, index) => ({
      ...user,
      verified: <span className={`badge ${user.is_account_verified ? "bg-success" : "bg-danger"}`}>{user.is_account_verified ? "YES" : "NO"}</span>,
      index: index + 1,
    }));

  return (
    <>
      <SortableTable data={dataWithVerifiedBadge} columns={columns} renderActions={renderActions} />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const FarmSectionTable = ({ farmSections }) => {
  const { apiDelete } = useContext(UserContext);
  const history = useHistory();
  const [selectedFarmSection, setSelectedFarmSection] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Size", accessor: "size" },
    { header: "Farm", accessor: "farmName" },
    { header: "Start Date", accessor: "start_date" },
    { header: "End Date", accessor: "end_date" },
    { header: "Status", accessor: "status" },
  ];

  const handleDelete = async () => {
    if (selectedFarmSection) {
      try {
        const response = await apiDelete(`farmSections/deleteFarmSections/${selectedFarmSection.id}`);
        if (response.code == 200) window.location.reload();
        else alert("Something Went Wrong.");
      } catch (error) {
        // Handle error, e.g., show an error message
        console.error("There was an error deleting the farm!", error);
      } finally {
        setShowModal(false);
        setSelectedFarmSection(null);
      }
    }
  };

  const renderActions = (section) => (
    <>
      <Button variant="outline-primary" size="sm" className="me-2" onClick={() => history.push(`/UpdateFarmSection/${section.id}`)}>
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => {
          setSelectedFarmSection(section);
          setShowModal(true);
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </>
  );

  const dataWithStatusClass = farmSections.map((section, index) => ({
    ...section,
    farmName: section.farm?.name,
    start_date: formatDate(section?.start_date),
    end_date: formatDate(section?.end_date),
    status: <span className={`badge ${section?.status ? "bg-success" : "bg-danger"}`}>{section?.status ? "ACTIVE" : "INACTIVE"}</span>,
    index: index + 1,
  }));

  return (
    <>
      <SortableTable data={dataWithStatusClass} columns={columns} renderActions={renderActions} />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this farm section?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const TasksTable = ({ tasks }) => {
  const { apiDelete } = useContext(UserContext);
  const [selectedTask, setSelectedTask] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const { authInfo } = useContext(AuthContext);
  const history = useHistory();
  const columns = [
    { header: "Task Description", accessor: "description" },
    { header: "Assigned To", accessor: "assignedTo" },
    { header: "Farm", accessor: "farm" },
    { header: "Farm Section", accessor: "farmSection" },
    { header: "Start Date", accessor: "startDate" },
    { header: "Completion Date", accessor: "completionDate" },
    // { header: 'Feedback', accessor: 'feedback' },
    { header: "Status", accessor: "status" },
  ];

  const handleDelete = async () => {
    if (selectedTask) {
      try {
        const response = await apiDelete(`tasks/deleteTask/${selectedTask.id}`);
        if (response.code == 200) window.location.reload();
        else alert("Something Went Wrong.");
      } catch (error) {
        // Handle error, e.g., show an error message
        console.error("There was an error deleting the task!", error);
      } finally {
        setShowModal(false);
        setSelectedTask(null);
      }
    }
  };

  const renderActions = (task) => {
    return (
      <>
        {authInfo.data.role != "manager" && (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>}>
              <Button variant="outline-primary" size="sm" className="me-2" onClick={() => history.push(`/UpdateTask/${task.id}`)}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}>
              <Button
                variant="outline-danger"
                size="sm"
                className="me-2"
                onClick={() => {
                  setSelectedTask(task);
                  setShowModal(true);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </OverlayTrigger>
          </>
        )}

        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>SubTasks</Tooltip>}>
          <Button variant="outline-info" size="sm" className="me-2" onClick={() => history.push(`/SubTasks/${task.id}`)}>
            <FontAwesomeIcon icon={faTasks} />
          </Button>
        </OverlayTrigger>
      </>
    );
  };

  

  const dataWithIndex = tasks.map((task, index) => ({
    ...task,
    description: task.detail,
    assignedTo: task.user.name,
    farm: task.farm.name,
    farmSection: task.farmSection.name,
    startDate: formatDate(task.start_date),
    completionDate: formatDate(task.complition_date),
    index: index + 1,
  }));

  return (
    <>
      <SortableTable data={dataWithIndex} columns={columns} renderActions={renderActions} />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this task?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const SubtasksTable = ({ subtasks }) => {
  const { apiDelete } = useContext(UserContext);
  const { authInfo } = useContext(AuthContext);
  const history = useHistory();
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    { header: "Detail", accessor: "detail" },
    { header: "Task", accessor: "task" },
    { header: "Assigned To", accessor: "assignedTo" },
    { header: "Wage Amount", accessor: "wage_amount" },
    { header: "Wage Type", accessor: "wage_type" },
    { header: "Wage Count", accessor: "wage_count" },
    { header: "Status", accessor: "status" },
  ];

  const handleDelete = async () => {
    if (selectedSubtask) {
      try {
        const response = await apiDelete(`subtasks/deleteSubTask/${selectedSubtask.id}`);
        if (response.code === 200) window.location.reload();
        else alert("Something Went Wrong.");
      } catch (error) {
        console.error("There was an error deleting the subtask!", error);
      } finally {
        setShowModal(false);
        setSelectedSubtask(null);
      }
    }
  };

  const renderActions = (subtask) => (
    <>
      {authInfo.data.role !== "manager" && (
        <>
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>}>
            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => history.push(`/UpdateSubtask/${subtask.id}`)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => {
                setSelectedSubtask(subtask);
                setShowModal(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </OverlayTrigger>
        </>
      )}
    </>
  );

  const dataWithIndex = subtasks.map((subtask, index) => ({
    ...subtask,
    task: subtask.task.detail,
    assignedTo: subtask.user.name,
    index: index + 1,
  }));

  return (
    <>
      <SortableTable data={dataWithIndex} columns={columns} renderActions={renderActions} />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this subtask?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const TransactionTable = ({ transactions }) => {
  const columns = [
    { header: "Description", accessor: "description" },
    { header: "By", accessor: "by" },
    { header: "Farm", accessor: "farm" },
    { header: "Farm Section", accessor: "farmSection" },
    { header: "Date", accessor: "date" },
    { header: "Amount", accessor: "amount" },
  ];

  const renderActions = (transaction) => (
    <>
      <Button variant="outline-primary" size="sm" className="me-2">
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      <Button variant="outline-danger" size="sm">
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </>
  );

  const dataWithIndex = transactions.map((transaction, index) => ({
    ...transaction,
    index: index + 1,
  }));

  return <SortableTable data={dataWithIndex} columns={columns} renderActions={renderActions} />;
};

export const IncomeTable = ({ incomes }) => {
  const { apiDelete } = useContext(UserContext);
  const { authInfo } = useContext(AuthContext);
  const history = useHistory();
  const [selectedIncome, setSelectedIncome] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Type", accessor: "incomeType" },
    { header: "User", accessor: "user" },
    { header: "Farm", accessor: "farm" },
    { header: "Farm Section", accessor: "farmSection" },
    { header: "Date", accessor: "date" },
    { header: "Amount", accessor: "Amount" },
    { header: "Receipt Number", accessor: "recipte_number" },
  ];

  const handleDelete = async () => {
    if (selectedIncome) {
      try {
        const response = await apiDelete(`accounts/deleteIncome/${selectedIncome.id}`);
        if (response.code === 200) window.location.reload();
        else alert("Something Went Wrong.");
      } catch (error) {
        console.error("There was an error deleting the income!", error);
      } finally {
        setShowModal(false);
        setSelectedIncome(null);
      }
    }
  };

  const renderActions = (income) => (
    <>
      {authInfo.data.role !== "manager" && (
        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}>
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => {
              setSelectedIncome(income);
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );

  const dataWithIndex = incomes.map((income, index) => ({
    ...income,
    farm: income.farm.name,
    farmSection: income.farmSection.name,
    user: income.user.name,
    date: formatDate(income.date),
    index: index + 1,
  }));

  return (
    <>
      <SortableTable data={dataWithIndex} columns={columns} renderActions={renderActions} />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this income?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ExpenseTable = ({ expenses }) => {
  const { apiDelete } = useContext(UserContext);
  const { authInfo } = useContext(AuthContext);
  const history = useHistory();
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Type", accessor: "expensesType" },
    { header: "User", accessor: "user" },
    { header: "Farm", accessor: "farm" },
    { header: "Farm Section", accessor: "farmSection" },
    { header: "Date", accessor: "date" },
    { header: "Amount", accessor: "Amount" },
  ];

  const handleDelete = async () => {
    if (selectedExpense) {
      try {
        const response = await apiDelete(`accounts/deleteExpense/${selectedExpense.id}`);
        if (response.code === 200) window.location.reload();
        else alert("Something Went Wrong.");
      } catch (error) {
        console.error("There was an error deleting the expense!", error);
      } finally {
        setShowModal(false);
        setSelectedExpense(null);
      }
    }
  };

  const renderActions = (expense) => (
    <>
      {authInfo.data.role !== "manager" && (
        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}>
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => {
              setSelectedExpense(expense);
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );

  const dataWithIndex = expenses.map((expense, index) => ({
    ...expense,
    user: expense.user.name,
    farm: expense.farm.name,
    farmSection: expense?.farmSection?.name,
    date: formatDate(expense.date),
    index: index + 1,
  }));

  return (
    <>
      <SortableTable data={dataWithIndex} columns={columns} renderActions={renderActions} />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this expense?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const { country, countryImage, overallRank, overallRankChange, travelRank, travelRankChange, widgetsRank, widgetsRankChange } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image src={countryImage} className="image-small rounded-circle me-2" />
            <div>
              <span className="h6">{country}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{overallRank ? overallRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">{travelRank ? travelRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">{widgetsRank ? widgetsRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map((r) => (
              <TableRow key={`ranking-${r.id}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const TransactionsTable = () => {
  const totalTransactions = transactions.length;

  const TableRow = (props) => {
    const { invoiceNumber, subscription, price, issueDate, dueDate, status } = props;
    const statusVariant = status === "Paid" ? "success" : status === "Due" ? "warning" : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
            {invoiceNumber}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{subscription}</span>
        </td>
        <td>
          <span className="fw-normal">{issueDate}</span>
        </td>
        <td>
          <span className="fw-normal">{dueDate}</span>
        </td>
        <td>
          <span className="fw-normal">${parseFloat(price).toFixed(2)}</span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>{status}</span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Bill For</th>
              <th className="border-bottom">Issue Date</th>
              <th className="border-bottom">Due Date</th>
              <th className="border-bottom">Total</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((t) => (
              <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: "5%" }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: "5%" }}>
          <ul className="ps-0">
            {usage.map((u) => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: "50%" }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: "40%" }}>
          <pre>
            <Card.Link href={link} target="_blank">
              Read More <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Card.Link>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table responsive className="table-centered rounded" style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: "5%" }}>
                Name
              </th>
              <th className="border-0" style={{ width: "5%" }}>
                Usage
              </th>
              <th className="border-0" style={{ width: "50%" }}>
                Description
              </th>
              <th className="border-0" style={{ width: "40%" }}>
                Extra
              </th>
            </tr>
          </thead>
          <tbody>
            {commands.map((c) => (
              <TableRow key={`command-${c.id}`} {...c} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
