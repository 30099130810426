import React, { useState } from "react";
import { Card, Table, Button, FormControl, InputGroup, Pagination } from "@themesberg/react-bootstrap";

const SortableTable = ({ data, columns, renderActions, itemsPerPage = 10, noActions = false, isHtmlContent = false, footer }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredData = data.filter((item) => 
    columns.some((column) => 
      (item[column.accessor] ?? "").toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const sortedData = filteredData.sort((a, b) => {
    if (!sortField) return 0;
    const fieldA = (a[sortField] ?? "").toString().toLowerCase();
    const fieldB = (b[sortField] ?? "").toString().toLowerCase();

    if (sortOrder === "asc") {
      return fieldA > fieldB ? 1 : -1;
    } else {
      return fieldA < fieldB ? 1 : -1;
    }
  });

  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedData = sortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <InputGroup className="mb-3" style={{ width: "300px" }}>
          <FormControl placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" value={searchQuery} onChange={handleSearch} />
        </InputGroup>
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: "5%" }}>
                #
              </th>
              {columns.map((column) => (
                <th key={column.accessor} className="border-0" onClick={() => handleSort(column.accessor)} style={{ cursor: "pointer" }}>
                  {column.header}
                </th>
              ))}
              {!noActions && <th className="border-0">Actions</th>}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr key={`item-${item.id}`}>
                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                {columns.map((column) => (
                  <td key={column.accessor}>
                    {isHtmlContent ? (
                      <span dangerouslySetInnerHTML={{ __html: item[column.accessor] ?? "" }}></span>
                    ) : (
                      item[column.accessor] ?? ""
                    )}
                  </td>
                ))}
                {!noActions && <td style={{ width: "10%" }}>{renderActions && renderActions(item)}</td>}
              </tr>
            ))}
          </tbody>
          {footer && footer}
        </Table>
        <Pagination className="mt-3">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </Card.Body>
    </Card>
  );
};

export default SortableTable;
