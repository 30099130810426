import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Button, Card, Alert } from "@themesberg/react-bootstrap";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";

const AddExpense = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [expensesType, setExpensesType] = useState("utility");
  const [userId, setUserId] = useState("");
  const [farmId, setFarmId] = useState("");
  const [farmSectionId, setFarmSectionId] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [amount, setAmount] = useState("");

  const [users, setUsers] = useState([]);
  const [farms, setFarms] = useState([]);
  const [farmSections, setFarmSections] = useState([]);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const history = useHistory();
  const { authInfo } = useContext(AuthContext);
  const { apiGet, apiPostPut, getFarms, getFarmSections, getUsers } = useContext(UserContext);

  const handleFormChange = (id) => {
    setFarmId(id);
    const fetch = async () => {
      const data = await getFarmSections(id);
      setFarmSections(data);
    };

    fetch();
  };

  useEffect(() => {
    const fetch = async () => {
      setUsers(await getUsers("manager,employee"));
    };
    fetch();

    apiGet(`farms/${id}`)
      .then((farm) => {
        setFarms([farm.result]);
      })
      .catch((error) => {
        console.error("Error fetching farms:", error);
        setAlert({ show: true, message: "An error occurred while fetching the farms data. Please try again.", variant: "danger" });
      });
  }, [id]);

  const validate = () => {
    let errors = {};
    if (!name) {
      errors.name = "Name is required";
    }
    if (!expensesType) {
      errors.expensesType = "Expenses type is required";
    }
    if (!userId) {
      errors.userId = "User is required";
    }
    if (!farmId) {
      errors.farmId = "Farm is required";
    }
    if (!farmSectionId) {
      errors.farmSectionId = "Farm section is required";
    }
    if (!date) {
      errors.date = "Date is required";
    }
    if (!amount) {
      errors.amount = "Amount is required";
    }
  

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      if (id) {
        const response = await apiPostPut("accounts/addExpenses", { name, discription:description, expensesType, user_id: userId, farm_id: farmId, farm_section_id: farmSectionId, date, Amount: amount});
        setAlert({ show: true, message: "Expense added successfully!", variant: "success" });
      } else {
      }
      history.push("/Expense/" + farmId);
    } catch (error) {
      console.error("Error saving expense:", error);
      setAlert({ show: true, message: "An error occurred while saving the expense. Please try again.", variant: "danger" });
    }
  };

  return (
    <div style={{ minHeight: "85vh" }}>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">Add Expense</h5>
          {alert.show && (
            <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
              {alert.message}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter expense name" value={name} onChange={(e) => setName(e.target.value)} isInvalid={!!errors.name} required />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Expenses Type</Form.Label>
              <Form.Control as="select" value={expensesType} onChange={(e) => setExpensesType(e.target.value)} isInvalid={!!errors.expensesType} required>
                <option value="utility">Utility</option>
                <option value="payroll">Payroll</option>
                <option value="taxes">Taxes</option>
                <option value="rent">Rent</option>
                <option value="travel">Travel</option>
                <option value="maintenance">Maintenance</option>
                <option value="other">Other</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.expensesType}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>User</Form.Label>
              <Form.Control as="select" value={userId} onChange={(e) => setUserId(e.target.value)} isInvalid={!!errors.userId} required>
                <option value="">Select a user</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.userId}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Farm</Form.Label>
              <Form.Control as="select" value={farmId} onChange={(e) => handleFormChange(e.target.value)} isInvalid={!!errors.farmId} required>
                <option value="">Select a farm</option>
                {farms.map((farm) => (
                  <option key={farm.id} value={farm.id}>
                    {farm.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.farmId}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Farm Section</Form.Label>
              <Form.Control as="select" value={farmSectionId} onChange={(e) => setFarmSectionId(e.target.value)} isInvalid={!!errors.farmSectionId} required>
                <option value="">Select a farm section</option>
                {farmSections.map((section) => (
                  <option key={section.id} value={section.id}>
                    {section.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.farmSectionId}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} isInvalid={!!errors.date} required />
              <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter amount" value={amount} onChange={(e) => setAmount(e.target.value)} isInvalid={!!errors.amount} required />
              <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
            </Form.Group>
         
            <Button variant="primary" type="submit">
              Add Expense
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddExpense;
