import React, { createContext, useEffect, useState } from "react";
import { BASE_URL } from "../config";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authInfo, setAuthInfo] = useState(null); // Initially set to null
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // Retrieve token from local storage when the component mounts
    const savedAuthInfo = localStorage.getItem("authInfo");
    if (savedAuthInfo) {
      setAuthInfo(JSON.parse(savedAuthInfo));
    } else {
      setAuthInfo({}); // Set authInfo to an empty object if nothing is in localStorage
    }
  }, []);

  const Authentication = async (username, password) => {
    try {
      setIsLoading(true);
      if (username === "" || password === "") {
        setIsLoading(false);
        return; // If username or password is empty, exit function
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      };

      const response = await fetch(BASE_URL + "auth/login", requestOptions);
      const result = await response.json();
      if (result.code === 200) {
        setAuthInfo(result.result);
        localStorage.setItem("authInfo", JSON.stringify(result.result)); // Save token to local storage
      } else {
        setErrorMessage(result.message);
      }
      return result;
    } catch (error) {
      setErrorMessage("An error occurred during authentication.");
    } finally {
      setIsLoading(false);
    }
  };

  const isAuthenticated = () => {
    return authInfo && Object.keys(authInfo).length > 0;
  };

  const logout = () => {
    setAuthInfo({});
    localStorage.removeItem("authInfo"); // Remove token from local storage
    history.push("/");
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        authInfo,
        errorMessage,
        Authentication,
        isAuthenticated,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
